import { Flex, Spinner } from '@chakra-ui/react';

export default function Home() {
  return (
    <Flex
      alignItems="center"
      direction="column"
      justifyContent="center"
      minHeight="100vh"
    >
      <Spinner color="green.500" size="xl" speed="0.65s" />
    </Flex>
  );
}
